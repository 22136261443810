import React, { useState, useEffect, useContext } from "react";
import logo from "../assets/images/logo.png";
import { IconGizi } from "../assets/icons/icon";
import { GrHomeRounded } from "react-icons/gr";
import { TbReceipt } from "react-icons/tb";
import { LiaClipboardListSolid, LiaShippingFastSolid } from "react-icons/lia";
import { PiUsersThree } from "react-icons/pi";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { TbCategory } from "react-icons/tb";
import { ImGift } from "react-icons/im";
import { LuSchool2 } from "react-icons/lu";
import { LiaCoinsSolid } from "react-icons/lia";
import { MdCorporateFare } from "react-icons/md";
import { GiChickenOven } from "react-icons/gi";
import { Link, useLocation } from "react-router-dom";
import { HeaderTitleContext } from "./headerTitle";
import { SlArrowRight } from "react-icons/sl";
import { MdOutlineManageAccounts } from "react-icons/md";
import { GoQuestion } from "react-icons/go";
import { FaBoxOpen } from "react-icons/fa";

const sidebar = [
  {
    title: "Dashboard",
    icon: <GrHomeRounded className="w-6 h-6" />,
    path: "/",
  },
  {
    title: "Dashboard Gizi",
    icon: <IconGizi className="w-6 h-6" />,
    path: "#",
    submenu: [
      {
        title: " - Data Nasional",
        path: "/dashboardgizi/datanasional",
      },
      {
        title: " - Data Gizi Provinsi",
        path: "/dashboardgizi/datagizi",
      },
      {
        title: " - Perkembangan Gizi",
        path: "/dashboardgizi/perkembangangizi",
      },
      {
        title: " - Angka Kecukupan Gizi",
        path: "/dashboardgizi/angkagizi",
      },
    ],
  },
  {
    title: "Fitur Transaksi",
    icon: <TbReceipt className="w-6 h-6" />,
    path: "/fitur-transaksi",
  },
  {
    title: "Fitur Kurir",
    icon: <LiaShippingFastSolid className="w-6 h-6" />,
    path: "/fitur-distributor",
  },
  {
    title: "Kontrol Pengguna",
    icon: <PiUsersThree className="w-6 h-6" />,
    path: "/kontrol-pengguna",
  },
  {
    title: "Produk",
    icon: <FaBoxOpen className="w-6 h-6" />,
    path: "#",
    submenu: [
      {
        title: " - Produk",
        path: "/produk/1",
      },
    ],
  },
  {
    title: "Faktur",
    icon: <LiaFileInvoiceSolid className="w-6 h-6" />,
    path: "#",
    submenu: [
      {
        title: "- Subsidi",
        path: "/faktur/subsidi",
      },
      {
        title: "- Non Subsidi",
        path: "/faktur/non-subsidi",
      },
    ],
  },
  {
    title: "Kategori",
    icon: <TbCategory className="w-6 h-6" />,
    path: "#",
    submenu: [
      {
        title: "- Kategori Utama",
        path: "/kategori-utama",
      },
      {
        title: "- Sub Kategori",
        path: "/sub-kategori",
      },
      {
        title: "- Spesifik Kategori",
        path: "/spesifik-kategori",
      },
    ],
  },
  { title: "Acara", icon: <ImGift className="w-6 h-6" />, path: "/acara" },
  {
    title: "Sekolah",
    icon: <LuSchool2 className="w-6 h-6" />,
    path: "/sekolah",
  },
  {
    title: "Tarif",
    icon: <LiaCoinsSolid className="w-6 h-6" />,
    path: "/tarif",
  },
  {
    title: "Profil Perusahaan",
    icon: <MdCorporateFare className="w-6 h-6" />,
    path: "#",
    submenu: [
      {
        title: "- MBG",
        path: "/profil-perusahaan/mbg",
      },
    ],
  },
  {
    title: "Pangan Olahan",
    icon: <GiChickenOven className="w-6 h-6" />,
    path: "#",
    submenu: [
      {
        title: "- Makanan Olahan",
        path: "/pangan-olahan/makanan-olahan",
      },
      {
        title: "- Bahan Olahan",
        path: "/pangan-olahan/bahan-olahan",
      },
    ],
  },
  {
    title: "Tinjau Pesanan",
    icon: <LiaClipboardListSolid className="w-6 h-5" />,
    path: "/tinjau-pesanan",
  },
  {
    title: "Pengajuan Hapus Akun",
    icon: <MdOutlineManageAccounts className="w-6 h-5" />,
    path: "/hapus-akun",
  },
  {
    title: "Pusat Bantuan",
    icon: <GoQuestion className="w-6 h-6" />,
    path: "#",
    submenu: [
      {
        title: "- Judul Utama",
        path: "/pusat-bantuan/judul-utama",
      },
      {
        title: "- Sub Judul",
        path: "/pusat-bantuan/sub-judul",
      },
      {
        title: "- Spesifik",
        path: "/pusat-bantuan/spesifik",
      },
    ],
  },
];

function Sidebar() {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(null);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const { setTitle } = useContext(HeaderTitleContext);

  useEffect(() => {
    const currentPath = location.pathname;
    const currentMenuIndex = sidebar.findIndex((menu) => currentPath === menu.path || (menu.submenu && menu.submenu.some((sub) => sub.path === currentPath)));
    setActiveIndex(currentMenuIndex);

    const currentSubmenuIndex = sidebar.findIndex((menu) => menu.submenu && menu.submenu.some((sub) => sub.path === currentPath));
    setExpandedMenu(currentSubmenuIndex);

    const activeMenu = sidebar.find((menu) => menu.path === currentPath || (menu.submenu && menu.submenu.some((sub) => sub.path === currentPath)));
    if (activeMenu) {
      setTitle(activeMenu.title);
    }
  }, [location.pathname, setTitle]);

  const handleMenuClick = (index, title) => {
    if (sidebar[index].submenu) {
      if (expandedMenu === index) {
        setExpandedMenu(null);
      } else {
        setExpandedMenu(index);
      }
    } else {
      setActiveIndex(index);
      setExpandedMenu(null);
    }
    setTitle(title);
  };

  const handleSubmenuClick = (submenuItem) => {
    setTitle(submenuItem.title);
  };

  return (
    <div className="lg:w-[280px] w-[60px] h-screen border-r border-[#C7CED2] bg-[#F7F7F7] fixed top-0 left-0">
      <div className="flex justify-center items-center pt-6">
        <img src={logo} alt="logo" className="w-[82px] h-[40px]" />
      </div>
      <div className="custom-scrollbar overflow-y-auto">
        <ul className="mt-4 px-2">
          {sidebar.map((menu, index) => (
            <li key={index}>
              <Link
                to={menu.path}
                className={`flex items-center justify-between w-full h-[50px] px-3 rounded-lg gap-2 my-2 text-[14px] font-normal leading-4 cursor-pointer transition-colors duration-200 no-underline ${
                  expandedMenu === index ? "lg:bg-[#F15A241A] text-orange-500" : activeIndex === index ? "lg:bg-[#F15A241A] text-orange-500" : "text-[#696B6D] lg:hover:bg-[#F15A241A] hover:text-orange-500"
                }`}
                onClick={() => handleMenuClick(index, menu.title)}>
                <div className={`flex items-center ${menu.submenu ? "w-[224px]" : "w-full"} gap-2`}>
                  <div className="w-6 h-6">{menu.icon}</div>
                  <span className="hidden lg:block">{menu.title}</span>
                </div>
                {menu.submenu && <SlArrowRight className={`transition-transform duration-300 ${expandedMenu === index ? "rotate-90" : ""}`} />}
              </Link>
              {expandedMenu === index && menu.submenu && (
                <ul className="ml-8">
                  {menu.submenu.map((submenuItem, subIndex) => (
                    <li key={subIndex}>
                      <Link
                        to={submenuItem.path}
                        className={`flex no-underline items-center w-full h-[36px] p-2 rounded-lg gap-2 my-1 text-[14px] font-normal cursor-pointer transition-colors duration-200 ${
                          location.pathname === submenuItem.path ? "text-orange-500" : "text-[#696B6D] hover:text-orange-500"
                        }`}
                        onClick={() => handleSubmenuClick(submenuItem)}>
                        {submenuItem.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
