import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const token = localStorage.getItem("token");
  const expirationTime = localStorage.getItem("expirationTime");

  if (!token || (expirationTime && Date.now() > parseInt(expirationTime, 10))) {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("expirationTime");
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
