import "./App.css";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PublicRoute from "./routes/publicRoute";
import PrivateRoute from "./routes/privateRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import { HeaderTitleProvider } from "./components/headerTitle";

const Dashboard = lazy(() => import("./pages/dashbord/index"));
const DataNasional = lazy(() => import("./pages/dashbord-gizi/data-nasional"));
const DataGizi = lazy(() => import("./pages/dashbord-gizi/data-gizi"));
const PerkembanganGizi = lazy(() => import("./pages/dashbord-gizi/perkembangan-gizi"));
const AngkaGizi = lazy(() => import("./pages/dashbord-gizi/angka-gizi"));
const Login = lazy(() => import("./pages/login"));
const ControlUser = lazy(() => import("./pages/control-user"));
const FiturTransaksi = lazy(() => import("./pages/fitur-transaksi"));
const FiturDistributor = lazy(() => import("./pages/fitur-distributor"));
const Product = lazy(() => import("./pages/product"));
const MainCategory = lazy(() => import("./pages/category/kategori-utama"));
const SubCategory = lazy(() => import("./pages/category"));
const SpecificCat = lazy(() => import("./pages/category/spesifik-kategori"));
const FakturNonSubsidi = lazy(() => import("./pages/invoice/non-subsidi"));
const FakturSubsidi = lazy(() => import("./pages/invoice/subsidi"));
const Tarif = lazy(() => import("./pages/tarif"));
const CompanyProfile = lazy(() => import("./pages/compro"));
const Events = lazy(() => import("./pages/event"));
const MakananOlahan = lazy(() => import("./pages/pangan-olahan/makanan-olahan"));
const BahanOlahan = lazy(() => import("./pages/pangan-olahan/bahan-olahan"));
const TinjauPesanan = lazy(() => import("./pages/tinjau-pesanan"));
const HapusAkun = lazy(() => import("./pages/hapus-akun"));
const JudulUtama = lazy(() => import("./pages/pusat-bantuan/judul-utama"));
const SubJudul = lazy(() => import("./pages/pusat-bantuan/sub-judul"));
const SpesifikJudul = lazy(() => import("./pages/pusat-bantuan/spesifik"));
const Profile = lazy(() => import("./pages/Profile"));
const School = lazy(() => import("./pages/school"));

function App() {
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLogged(token !== null);
  }, []);

  // heeh
  return (
    <HeaderTitleProvider>
      <BrowserRouter>
        <div className="relative font-work-sans">
          <Sidebar />
          <Header />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route element={<PublicRoute />}>
                <Route path="/login" element={<Login logged={isLogged} />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboardgizi/datanasional" element={<DataNasional />} />
                <Route path="/dashboardgizi/datagizi" element={<DataGizi />} />
                <Route path="/dashboardgizi/perkembangangizi" element={<PerkembanganGizi />} />
                <Route path="/dashboardgizi/angkagizi" element={<AngkaGizi />} />
                <Route path="/fitur-transaksi" element={<FiturTransaksi />} />
                <Route path="/kontrol-pengguna" element={<ControlUser />} />
                <Route path="/fitur-distributor" element={<FiturDistributor />} />
                <Route path="/produk/:id" element={<Product />} />
                <Route path="/kategori-utama" element={<MainCategory />} />
                <Route path="/sub-kategori" element={<SubCategory />} />
                <Route path="/spesifik-kategori" element={<SpecificCat />} />
                <Route path="/faktur/non-subsidi" element={<FakturNonSubsidi />} />
                <Route path="/faktur/subsidi" element={<FakturSubsidi />} />
                <Route path="/sekolah" element={<School />} />
                <Route path="/tarif" element={<Tarif />} />
                <Route path="/profil-perusahaan/mbg" element={<CompanyProfile />} />
                <Route path="/acara" element={<Events />} />
                <Route path="/pangan-olahan/makanan-olahan" element={<MakananOlahan />} />
                <Route path="/pangan-olahan/bahan-olahan" element={<BahanOlahan />} />
                <Route path="/tinjau-pesanan" element={<TinjauPesanan />} />
                <Route path="/hapus-akun" element={<HapusAkun />} />
                <Route path="/pusat-bantuan/judul-utama" element={<JudulUtama />} />
                <Route path="/pusat-bantuan/sub-judul" element={<SubJudul />} />
                <Route path="/pusat-bantuan/spesifik" element={<SpesifikJudul />} />
                <Route path="/profile" element={<Profile />} />
              </Route>
            </Routes>
          </Suspense>
        </div>
      </BrowserRouter>
    </HeaderTitleProvider>
  );
}

export default App;
