import React, { useContext, useState } from "react";
import notification from "../assets/icons/notification.png";
import profilePicture from "../assets/images/profilePicture.png";
import { IoIosArrowDown } from "react-icons/io";
import { GoSearch } from "react-icons/go";
import { HeaderTitleContext } from "./headerTitle";
import { useNavigate, useLocation } from "react-router-dom";

function Header() {
  const { title } = useContext(HeaderTitleContext);
  const [isLogoutPopupVisible, setIsLogoutPopupVisible] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();

  const isLoginPage = location.pathname === "/login";

  if (isLoginPage) {
    return null;
  }

  const toggleLogoutPopup = () => {
    setIsLogoutPopupVisible(!isLogoutPopupVisible);
  };

  const handleLogout = () => {
    console.log("User logged out");
    localStorage.removeItem("token");
    navigate("/login");
    setIsLogoutPopupVisible(false)
  };

  const handleProfile = () => {
    navigate("/profile");
    setIsLogoutPopupVisible(false)
  }

  return (
    <div className="bg-[#F7F7F7] border-b border-[#C7CED2] fixed top-0 left-0 right-0 h-[72px] flex justify-between items-center py-3 pl-6 pr-4 gap-6 lg:left-[280px] lg:w-[calc(100%-280px)] z-10">
      <div>
        <div className="text-[#4A4D4F] text-[18px] leading-6 font-semibold">
          {title}
        </div>
      </div>
      <div className="flex items-center gap-6">
        {/* Pembungkus input search dengan posisi relative */}
        <div className="relative w-full max-w-[350px]">
          <input
            className="rounded-lg border outline-none border-[#B5B6B7] py-2 px-10 w-full h-[40px]" // Ubah padding kiri untuk ikon
            placeholder="Cari.."
            type="text"
          />
          {/* Ikon cari dengan posisi absolute */}
          <GoSearch
            size={24}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#B5B6B7]"
          />
        </div>
        <div
          className="flex items-center gap-6 pr-8 cursor-pointer"
          onClick={toggleLogoutPopup}
        >
          <img src={notification} alt="notification" className="w-6 h-6" />
          <div className="flex items-center relative">
            <img
              src={profilePicture}
              alt="avatar"
              className="w-8 h-8 rounded-full cursor-pointer"
            />
            <div className="ml-2 flex pr-8 items-center">
              <div>
                <div className="text-[#696B6D] text-[16px] lg:w-[136px] lg:h-4 leading-4 font-semibold">
                  Ahmad Mambaus
                </div>
                <div className="text-[14px] leading-4 font-normal text-[#696B6D]">
                  Direktur
                </div>
              </div>
              <IoIosArrowDown
                className="text-black ml-3 cursor-pointer"
                size={16}
              />
            </div>

            {isLogoutPopupVisible && (
              <div
                className="absolute top-12 right-0 bg-white border border-[#C7CED2] rounded-md shadow-lg w-40 z-50"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="p-2 flex flex-col gap-2">
                  <button
                    className="text-[#4A4D4F] text-[14px] hover:text-white hover:bg-custom-gradient py-1 rounded-lg"
                    onClick={handleLogout}
                  >
                    Keluar
                  </button>
                  <button
                    className="text-[#4A4D4F] text-[14px] hover:text-white hover:bg-custom-gradient py-1 rounded-lg"
                    onClick={handleProfile}
                  >
                    Profil
                  </button>
                  <button
                    className="text-[#696B6D] text-[14px] hover:text-white hover:bg-custom-gradient py-1 rounded-lg"
                    onClick={toggleLogoutPopup}
                  >
                    Kembali
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
